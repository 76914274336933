
import { useEffect, useRef } from "react";
import "./App.css";

function App() {
 const text = useRef();

useEffect(() => {
  window.addEventListener("mousemove", (e) => {
    text.current.style.top = (window.innerHeight / 2) - (e.clientY /100) + 10 + 'px';
    text.current.style.left = (window.innerWidth / 2) - (e.clientX /100) +10 + 'px';
  })
}, [])

  return (
    <div className="app">
      <h1 ref={text}>WEBSITE UNDER CONSTRUCTION</h1>
      <h1 id="under">WEBSITE UNDER CONSTRUCTION</h1>
    </div>
  );
}

export default App;
